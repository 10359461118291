@use '@angular/material' as mat;

@import "settings/fonts";
@import "settings/variables";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 16px;
    font: inherit;
    vertical-align: baseline;
    color: $dark-color;
    font-family: Texta;
    box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    font-family: Texta, sans-serif;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1,
h2,
h3 {
    color: $dark-color;
    font-size: 16px;
    font-weight: 700;

    @media screen and (max-width: 1500px){
        font-size: 16px;
    }
}

.textSmall{
    font-size: 12px;
}

.textMiddle{
    font-size: 16px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background: #FBFBFB }

::-webkit-scrollbar-track
{
	background-color: transparent;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    background-color: map-get($primary-palette, 500);
}


@include mat.core();
$quiron-primary: mat.define-palette($primary-palette);
$quiron-accent: mat.define-palette($accent-palette);
$quiron-new-warn: mat.define-palette(mat.$red-palette);
$quiron-new-theme: mat.define-light-theme((
  color: (
    primary: $quiron-primary,
    accent: $quiron-accent,
    warn: $quiron-new-warn,
  )
));
@include mat.all-component-themes($quiron-new-theme);

.paginator-container {
    display: flex;
    gap: 6px;
    margin: 0px 6px;
}
  
.paginator-button {
    border-radius: 8px!important;
    width: 30px!important;
    height: 36px!important;
    padding: 10px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid #C8C8C8!important;

    &.no-action{
        pointer-events: none;
    }

    &.mat-mdc-paginator-navigation-previous,
    &.mat-mdc-paginator-navigation-next {
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
        padding: 5px!important;
    }
}
  
.paginator-button:hover, .paginator-button__active {
    background-color: #000;
    color: #FFF;
}

iconify-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}

.table-actions{
    display: inline-flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    width: 90px;

    .table-button{
        all: unset;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-self: center;

        &[disabled]{
            pointer-events: none;

            iconify-icon{
                color: #D0D0D0;
            }
        }
    
        iconify-icon{
            font-size: 24px;
            color: #51C80B;
            transition: all 0.2s ease;
        }
    
        &:hover{
            iconify-icon{
                color: darken(#51C80B, 20);
            }
        }
    
        &.delete:hover{
            iconify-icon{
                color: red;
            }
        }
    }
}
.mat-mdc-cell{
    vertical-align: middle !important;
    overflow: visible !important;
    border-bottom: none!important;
    border-top: 1px solid #F5F5F5!important;
}

.mat-mdc-header-cell{
    vertical-align: middle!important;
    font-weight: bold!important;
    border: none!important;
    font-size: 1rem;
}

.mat-column-select{
    text-align: center;
    vertical-align: middle!important;
}

.mat-column-actions{
    text-align: right!important;
    vertical-align: middle!important;
}

.mdc-checkbox--disabled .mdc-checkbox__background{
    border: 1px solid #F0F0F0!important;
}

.mdc-checkbox{

    &__background{
        border-radius: 3px!important;
        width: 18px!important;
        height: 18px!important;
        border: 1px solid #71C115!important;
    }

    .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark{
        color: #FFF!important;
    }

    .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark{
        border-color: #FFF!important;
    }
}



.mat-mdc-row:has(.mat-mdc-checkbox-checked){
    background: #F9FFF3;

    .mat-mdc-cell{
        box-shadow: 0 -1px 0 #71C115 inset!important;
        border-top-color: #71C115 !important;
    }
}

