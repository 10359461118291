$dark-color: #3E3E3E;
$gray: #999999;
$light-gray: #BBB;
$black: #000;
$white: #FBFBFB;
$red: #F63B23;

$labels: (
    NULL: #2C640A,
    LOW: #71C115,
    MODERATE: #4865CB,
    HIGH: #F6D523,
    VERY-HIGH: #F68F23,
    EXTREME: #F63B23,
    CRITICAL: #000,
    EMPTY: #FFFFC0,
);

$box-shadow: 10px 0px 94px 0px rgba(0, 0, 0, 0.07);

$primary-palette: (
    50 : #fbfcf9,
    100 : #f4f9ef,
    200 : #edf5e4,
    300 : #e5f0d9,
    400 : #e0edd1,
    500 : #daeac9,
    600 : #d6e7c3,
    700 : #d0e4bc,
    800 : #cbe1b5,
    900 : #c2dba9,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$accent-palette: (
    50 : #eef8e3,
    100 : #d4ecb9,
    200 : #b8e08a,
    300 : #9cd45b,
    400 : #86ca38,
    500 : #71c115,
    600 : #69bb12,
    700 : #5eb30f,
    800 : #54ab0c,
    900 : #429e06,
    A100 : #dcffca,
    A200 : #baff97,
    A400 : #98ff64,
    A700 : #87ff4a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
