@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-Bold.woff2') format('woff2'),
        url('../../assets/fonts/Texta-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-BlackIt.woff2') format('woff2'),
        url('../../assets/fonts/Texta-BlackIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-BoldIt.woff2') format('woff2'),
        url('../../assets/fonts/Texta-BoldIt.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-HeavyIt.woff2') format('woff2'),
        url('../../assets/fonts/Texta-HeavyIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-Heavy.woff2') format('woff2'),
        url('../../assets/fonts/Texta-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-Black.woff2') format('woff2'),
        url('../../assets/fonts/Texta-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-Thin.woff2') format('woff2'),
        url('../../assets/fonts/Texta-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-It.woff2') format('woff2'),
        url('../../assets/fonts/Texta-It.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-LightIt.woff2') format('woff2'),
        url('../../assets/fonts/Texta-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-Light.woff2') format('woff2'),
        url('../../assets/fonts/Texta-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-MediumIt.woff2') format('woff2'),
        url('../../assets/fonts/Texta-MediumIt.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Texta Book';
    src: url('../../assets/fonts/Texta-BookIt.woff2') format('woff2'),
        url('../../assets/fonts/Texta-BookIt.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-Medium.woff2') format('woff2'),
        url('../../assets/fonts/Texta-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Texta-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Texta Book';
    src: url('../../assets/fonts/Texta-Book.woff2') format('woff2'),
        url('../../assets/fonts/Texta-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Texta';
    src: url('../../assets/fonts/Texta-ThintIt.woff2') format('woff2'),
        url('../../assets/fonts/Texta-ThintIt.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}